// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // firebase: {
  //   apiKey: '',
  //   authDomain: '',
  //   databaseURL: '',
  //   projectId: '',
  //   storageBucket: '',
  //   messagingSenderId: '',
  //   appId: '',
  //   measurementId: ''
  // },
  firebase: {
    apiKey: "AIzaSyDJzsTQQIWc5Dg9_PhbrPkDkaTifY3XU-4",
    authDomain: "uroz-y-asociados-despacho.firebaseapp.com",
    projectId: "uroz-y-asociados-despacho",
    storageBucket: "uroz-y-asociados-despacho.appspot.com",
    messagingSenderId: "524116550793",
    appId: "1:524116550793:web:4c77db809d8f247389d258"
    // apiKey: "AIzaSyC_EQ9CDKCVIBB4Y0Zrbg_7Y6RmsA9RXs4",
    // authDomain: "despacho-757b1.firebaseapp.com",
    // projectId: "despacho-757b1",
    // storageBucket: "despacho-757b1.appspot.com",
    // messagingSenderId: "489909804331",
    // appId: "1:489909804331:web:346b880ba9e24afbaa396e"
  },
  onesignal: {
    appId: '',
    googleProjectNumber: '',
    restKey: ''
  },
  stripe: {
    sk: ''
  },
  general: {
    symbol: '€',
    code: 'EUR'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
